@tailwind base;
@tailwind components;
@tailwind utilities;

.map-marker {
  cursor: pointer;
}
.active {
  background: white;
  border-radius: 9999px;
  color: #63b3ed;
}

.newstitle {
  font-family: "Open Sans Condensed", sans-serif;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(https://content.ikon.mn/ikon/images/hsprite.png) 0px -96px repeat-x;
  padding: 8px 8px 8px 8px;
  overflow: hidden;
  color: #fff;
  font-size: 20px;
  height: 80px;
  font-size: 20px;
  line-height: 32px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
}

.newstitle:hover {
  color: #fd3e0a;
}
